.head {
  &&_themes_skew {
    overflow: hidden;
    position: relative;
    margin: 3.5em 0;
    padding: .5em 0;
    background-color: var(--pink_l);
    transform: skewY(-3deg);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      padding-top: 50%;
      border-radius: 50%;
      background: radial-gradient(at 50%, color(var(--pink_l) l(+8%)), var(--pink_l) 75%);
    }
  }
}
