.home-about {
  overflow: visible !important;
  position: relative;
  background: url(../images/home-about_bg_2.png) 104.4% 76%/20% auto no-repeat;
  @media (--phone){
    background-size: 30% auto;
    background-position: 107.4% 70%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-14%, -30%);
    width: 30%;
    padding-top: 30%;
    background: url(../images/home-about_bg_1.png) 50%/contain no-repeat;
    @media (--phone){
      width: 70%;
      padding-top: 70%;
      transform: translate(-5em, -6em);
    }
  }
}
