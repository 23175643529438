.about {
  & &__img {
    @media (--phone){
      text-align: center;

      img {
        width: 50%;
      }
    }
  }
}
