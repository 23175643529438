.head {
  &&_themes_tilt {
    position: relative;
    padding: .3em 0;
    background-color: var(--pink_l);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      width: 50%;
      padding-top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      background: radial-gradient(at 50%, color( var(--pink_l) l(+11%) ), var(--pink_l) 75%);
    }
    
    &.head_themes_tilt_left {
      clip-path: polygon(0 0, calc(100% - 2em) 0, calc( 100% - 1em) 100%, 0 100%);
      @media (--desktop){
        clip-path: polygon(0 0, 55% 0, calc(55% + 1em) 100%, 0 100%);
      }

      &:before {
        left: 7%;
      }
    }

    &.head_themes_tilt_right {
      clip-path: polygon(2em 0, 100% 0,  100% 100%, 1em 100%);
      @media (--desktop){
        clip-path: polygon(calc(45% + 1em) 0, 100% 0,  100% 100%, 45% 100%);
      }

      &:before {
        right: 7%;
      }
    }
  }
}
