.enquiry {
  padding: 3em 0 5em;
  background: url(../images/bg_children.png) 20% 20%/40% auto no-repeat,
              url(../images/bg_enquiry.png) 10% 50%/110% auto no-repeat,
              radial-gradient(at 20% 60%, color(white b(+10%)), var(--pink) 50%);
  @media (--phone){
    background: url(../images/bg_children.png) 100% 85%/45% auto no-repeat,
                url(../images/bg_enquiry.png) 100% 10%/150% auto no-repeat,
                radial-gradient(at 20% 100%, color(white b(+10%)), var(--pink) 50%);

    .enquiry__title {
      text-align: center;
    }
  }

  .wpcf7 {
    @define-mixin enquiryInput {
      width: 100%;
      height: 100%;
      margin: 0;
      border: none;
      border-radius: 1.5em;
      background-color: color(white a(.6));
      color: var(--black);

      &::placeholder {
        opacity: 1;
        color: color( var(--black) l(+10%) );
        text-transform: uppercase;
      }
      &:-ms-placeholder {
        color: color( var(--black) l(+10%) );
        text-transform: uppercase;
      }
      &::-ms-placeholder {
        color: color( var(--black) l(+10%) );
        text-transform: uppercase;
      }
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="tel"] {
        @mixin enquiryInput;
      }

      &[type="submit"] {
        width: 100%;
        height: 100%;
        margin-top: 0;
        background-color: var(--green);
        border: none;
        border-radius: 1em;
        @media (--phone){
          display: block;
          width: 40%;
          margin: 0 auto;
        }
      }
    }

    textarea {
      @mixin enquiryInput;
      resize: none;
    }

    &-form {
      > p {
        display: grid;
        grid: auto-flow 2.2em / repeat(2, 1fr);
        grid-gap: .4em;
        @media (--phone){
          grid: auto-flow 2em / repeat(1, 1fr);
        }
      }

      .your-message {
        grid-area: span 2;
        @media (--phone){
          grid-area: span 3;
        }
      }
    }
  }
}
