.home-banner {
  position: relative;
  padding: 16% 0 3em;
  background: url(../images/bg_1.png) 15% 160%/160% auto no-repeat,
              radial-gradient(at 20% 30%, color( var(--pink) l(+20%) ), var(--pink) 50%);
  @media (--phone){
    background: url(../images/bg_1.png) 15% 0/150% auto no-repeat,
                radial-gradient(at 20% 20%, color( var(--pink) l(+20%) ), var(--pink) 20%);
  }
}
