.about {
  padding-bottom: 25%;
  background: url(../images/bg_about_1.png) -10% 16%/40% auto no-repeat,
              url(../images/bg_about_2.png) 100% -1.4em/30% auto no-repeat,
              url(../images/logo_mas.png) 50% 50.5%/6em auto no-repeat,
              url(../images/bg_cerification_1.png) 0 72.7%/24% auto no-repeat,
              url(../images/bg_cerification_2.png) 100% 77%/40% auto no-repeat;
  @media (--desktop){
    padding-bottom: 15%;
    background: url(../images/bg_about_1.png) -10% 11%/25% auto no-repeat,
                url(../images/bg_about_2.png) 100% 18%/30% auto no-repeat,
                url(../images/logo_mas.png) 20% 54%/8em auto no-repeat,
                url(../images/bg_cerification_1.png) 0 74%/17% auto no-repeat,
                url(../images/bg_cerification_2.png) 100% 82%/17% auto no-repeat;
  }
}
