.widget_product_search {
  .woocommerce-product-search {
    position: relative;

    .search-field {
      height: 2em;
      background-color: transparent;
      border-color: var(--red);
      border-radius: 2em;
      font: 400 16px/1.2 'Century Gothic', sans-serif;
      color: color( var(--gray) l(+40%) );
      @media (--phone){
        font-size: 14px;
      }

      &::placeholder {
        opacity: 1;
        color: var(--gray_l);
        text-transform: lowercase;
      }
      &::-ms-placeholder {
        color: var(--gray_l);
        text-transform: lowercase;
      }
      &:-ms-placeholder {
        color: var(--gray_l);
        text-transform: lowercase;
      }
    }

    button[type="submit"] {
      position: absolute;
      top: 0;
      right: 10px;
      bottom: 0;
      padding: 0 10px;
      background-color: transparent;
      border: none;
      font-size: 0;

      &:before {
        content: "\f002";
        font: normal normal normal 1rem/1.6 FontAwesome;
        color: color( var(--gray) l(+40%) );
      }
    }
  }
}
