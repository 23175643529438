.woo-product {
  & &__head {
    position: relative;
    padding: 3em 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 10%;
      z-index: var(--zIndex__middle);
      transform: skewY(5deg) translateY(-10%);
      width: 40%;
      padding-top: 35%;
      background: url(../images/product_head_img.png) center/contain no-repeat;
      @media (--phone){
        top: 40%;
        left: 70%;
        width: 40%;
        padding-top: 40%;
      }
    }
  }
}
