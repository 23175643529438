.woo-product {
  & &__title {
    overflow: hidden;
    position: relative;
    z-index: var(--zIndex__bottom);
    padding: .3em 20% .4em;
    background: var(--pink_l);
    text-align: right;
    font-size: 4rem;
    transform: skewY(-5deg);
    @media (--phone){
      padding-top: .2em;
      padding-bottom: .2em;
      font-size: 3.2rem;
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      width: 70%;
      padding-top: 70%;
      border-radius: 50%;
      background: radial-gradient(at 50%, color( var(--pink_l) l(+10%) ), var(--pink_l) 70%);
    }
  }
}
