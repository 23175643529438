.colors {
  &_white {
    color: var(--white);
  }
  &_black {
    color: var(--black);
  }
  &_red {
    color: var(--red);
  }
}
