.about {
  & &__cerification {
    .wpb_single_image {
      @media (--phone){
        img {
          width: auto;
          max-height: 5em;
        }
      }
    }
  }
}
