.home-banner {
  & &__title {
    position: absolute;
    top: 0;
    left: 45%;
    @media (--phone){
      position: static;
      padding-right: 15px;
      text-align: right;
      font-size: 1.6rem;
      transform: translateY(50%);
    }
    
    strong {
      font-weight: 400;
      font-size: .7em;
    }
  }
}
