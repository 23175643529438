.home-banner {
  & &__materials {
    .wpb_single_image {
      overflow: hidden;
      position: relative;
      border: 1px solid var(--pink);
      cursor: pointer;
      @media (--phone){
        border-width: 3px;
      }

      .wpb_heading {
        position: absolute;
        right: 1em;
        bottom: 1em;
        z-index: var(--zIndex__bottom);
        font: 700 1rem/1.2 'Century Gothic', sans-serif;
        color: var(--black);
      }

      &.colors_white {
        .wpb_heading {
          color: var(--white);
        }
      }

      .vc_single_image-img {
        transition: transform .3s cubic-bezier(.3, .3, .7, .7);
      }

      &:hover {
        .vc_single_image-img {
          z-index: 2;
          transform: scale(1.15);
        }
      }
    }
  }
}
