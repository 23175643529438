.footer-widgets {
  padding: 4em 0 1em;
  margin-top: -20%;
  background: linear-gradient(color(var(--pink) l(+18%)), var(--pink));
  clip-path: url(#footClipMobile);
  text-align: center;
  font-size: 16px;
  @media (--desktop){
    padding: 10% 0 3em;
    margin-top: -10%;
    clip-path: url(#footClipPc);

    .widget {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media (--ipad_mini){
    margin-top: -15%;
  }
  @media (--phone){
    font-size: 14px;
  }

  .widget {
    margin-bottom: 0;
    padding-top: 2em;

    &:last-child {
      margin-bottom: 2em;
    }
  }

  .widget-title {
    margin-top: 0;
    visibility: hidden;
    padding-bottom: .6em;
    font: 700 16px/1.2 'Century Gothic', sans-serif;
    color: var(--gray);
    @media (--desktop){
      visibility: visible;
    }
  }

  address {
    margin-bottom: 0;
    color: var(--white);

    a {
      color: var(--white);
    }

    @media (--desktop){
      br {
        display: none;
      }

      .space {
        display: inline-block;
        width: 5em;
        min-height: 1px;
      }
    }
  }

  #custom_html-4 {
    text-align: center;
    @media (--desktop){
      padding-top: 30%;

      p {
        display: block;
      }
    }

    p {
      display: inline-block;
      text-align: center;
    }

    a {
      width: 1.5em;
      color: var(--gray);
      font-size: 2rem;
      @media (--desktop){
        width: auto;
      }
    }
  }
}
