@for $i from 0 to 9 {
  .mt_p_$(i) {
    @media (--desktop){
      margin-top: $(i)em;
    }
  }
}
@for $i from 0 to 9 {
  .mt_m_$(i) {
    @media (--ipad){
      margin-top: $(i)em;
    }
    @media (--ipad_mini){
      margin-top: $(i)em;
    }
    @media (--phone){
      margin-top: $(i)em;
    }
  }
}
