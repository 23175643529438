@define-mixin formInput {
  margin-top: .3em;
  border: 1px solid color( var(--gray_l) b(+15%) );
  background-color: transparent;
  font: 400 14px/1.2 'Century Gothic,' sans-serif;
  color: var(--gray);

  &:forus {
    outline: 0;
  }

  &::placeholder {
    text-transform: capitalize;
    opacity: 1;
    color: var(--gray);
  }
  &::-ms-placeholder {
    text-transform: capitalize;
    color: var(--gray);
  }
  &:-ms-placeholder {
    text-transform: capitalize;
    color: var(--gray);
  }
}

.wpcf7 {
  input {
    &[type="tel"],
    &[type="text"],
    &[type="email"] {
      width: calc(( 100% - .6em ) / 3);
      padding-left: 1em;
      height: 2.4em;
      @mixin formInput;
      border-radius: .8em;
      @media (--phone){
        width: 100%;
      }
    }

    &[type="tel"],
    &[type="email"] {
      margin-left: .3em;
      @media (--phone){
        margin-left: 0;
      }
    }

    &[type="submit"] {
      display: block;
      margin: .7em auto 0;
      padding: .2em 1.7em;
      border-radius: 1em;
      background-color: var(--green);
      border-color: var(--green);
      font: 400 1rem/1.2 'Century Gothic', sans-serif;
    }
  }

  textarea {
    width: 100%;
    @mixin formInput;
    border-radius: 1em;
    resize: none;
  }
}
