@font-face {
  font-family: 'Century Gothic';
  src: url('../fonts/CenturyGothic-Bold.eot');
  src: url('../fonts/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CenturyGothic-Bold.woff2') format('woff2'),
  url('../fonts/CenturyGothic-Bold.woff') format('woff'),
  url('../fonts/CenturyGothic-Bold.ttf') format('truetype'),
  url('../fonts/CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Century Gothic';
  src: url('../fonts/CenturyGothic.eot');
  src: url('../fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CenturyGothic.woff2') format('woff2'),
  url('../fonts/CenturyGothic.woff') format('woff'),
  url('../fonts/CenturyGothic.ttf') format('truetype'),
  url('../fonts/CenturyGothic.svg#CenturyGothic') format('svg');
  font-weight: normal;
  font-style: normal;
}

