.social {
  & &__icon {
    display: block;
    width: 1.7em;
    height: 1.7em;
    border: 2px solid var(--pink);
    border-radius: 20px;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
    color: var(--pink);
  }
}
