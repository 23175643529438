.widget_product_categories {
  padding-top: 0 !important;

  .cat_21 & {
    .cat-item-22 {
      display: none;
    }
  }

  .cat_22 & {
    .cat-item-21 {
      display: none;
    }
  }

  .cat-item {
    &.cat-parent > a {
      display: none;
    }
  }

  .children {
    .cat-item {
      border-bottom: 1px solid var(--pink_l);
      font: 400 16px/1.2 'Century Gothic', sans-serif;
      @media (--phone){
        font-size: 14px;
      }

      > a {
        display: block;
        padding: .7em .5em;
        color: var(--black) !important;
        text-transform: uppercase;

        &:hover {
          color: var(--red) !important;
        }
      }

      &.current-cat {
        > a {
          color: var(--red) !important;
          font-weight: 700;
        }
      }
    }
  }
}
