.header-clone {
  display: none;
}
.site-header {
  position: absolute;
  background-color: transparent;

  &.fixed {
    position: absolute !important;
  }

  &.float-header {
    position: absolute;
    background-color: transparent;
  }
}
