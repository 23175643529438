.woocommerce {
  .products {
    margin-bottom: 0 !important;
    @media (--phone){
      padding-bottom: 3em !important;
    }

    .product-category {
      @media (--desktop){
        width: calc(( 100% - 2% * 4 ) / 5) !important;
        margin-right: 2% !important;

        &:nth-child(5){
          margin-right: 0 !important;
        }
      }

      .count {
        display: none;
      }

      img {
        width: auto !important;
        height: 9em !important;
        margin-left: auto !important;
        margin-right: auto !important;
        object-fit: cover;
      }

      .woocommerce-loop-category__title {
        text-transform: capitalize;
        font: 400 1rem/1.2 'Century Gothic', sans-serif;
      }

      > a {
        display: block;
        position: relative;
        z-index: var(--zIndex__bottom);

        &:before {
          content: '';
          position: absolute;
          top: 3.5em;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background-color: var(--gray_l);
          opacity: 0;
          box-shadow: 0 0 10px color(var(--gray) l(+20%));
        }

        &:hover {
          &:before {
            transform: translateY(14%);
            opacity: 1;
            transition: transform .4s, opacity .3s;
          }
        }
      }
    }
  }

  &.tax-product_cat,
  &.single-product {
    @media (--phone){
      .content-wrapper > .row {
        display: flex;
        flex-flow: row wrap;
      }
    }

    .page-wrap {
      padding: 3em 0 !important;
    }

    #primary {
      float: right;
      @media (--phone){
        float: none;
        width: 100%;
      }
    }

    #secondary {
      float: left;
      width: calc(100% / 12 * 2.4);
      @media (--phone){
        order: -1;
        float: none;
        width: 100%;
      }
    }

    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering {
      display: none;
    }
  }

  &.tax-product_cat {
    .button.product_type_simple {
      display: none;
    }

    .woocommerce-products-header {
      padding-bottom: 1.5em;

      .archive-title {
        font: 700 2rem/1.2 'Century Gothic', sans-serif;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .attachment-woocommerce_thumbnail {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 8em;
      object-fit: contain;
      margin: 0 auto !important;
    }

    .product__img {
      position: relative;
      padding-top: 100%;
      border: 1px solid var(--red);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: var(--zIndex__bottom);
        background-color: color( black a(.7) );
        opacity: 0;
      }
    }

    .product__read {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: var(--zIndex__middle);
      width: 80%;
      max-width: 12em;
      padding: .7em 1.4em;
      border-radius: 2em;
      border: none;
      background-color: var(--red);
      font: 400 12px/1.2 'Century Gothic', sans-serif;
      color: var(--white);
      opacity: 0;
    }

    .woocommerce-loop-product__title {
      padding: .8em 0 !important;
      font: 400 16px/1.2 'Century Gothic', sans-serif !important;
      @media (--phone){
        font-size: 14px !important;
      }
    }

    .woocommerce-LoopProduct-link {
      &:hover {
        .product__img {
          border-color: var(--pink);

          &:before {
            opacity: 1;
            transition: opacity .3s;
          }
        }

        .product__read {
          opacity: 1;
          transition: opacity .3s .1s;
        }

        .woocommerce-loop-product__title {
          font-weight: 700;
          color: var(--red);
        }
      }
    }

    .woocommerce-pagination {
      .page-numbers  {
        span,
        a {
          font-size: 14px;
        }
      }
    }
  }

  &.single-product {
    .page-wrap {
      padding: 3em 0 !important;
      @media (--phone){
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .product__title {
      padding-bottom: 1em;
      font-size: 1.6rem;
      text-align: center;

      br {
        @media (--desktop){
          display: none;
        }
      }
    }

    .wp-post-image {
      max-height: 10em;
      margin: 0 auto;
      object-fit: contain;
    }

    .woocommerce-product-gallery {
      padding: 1.4em 0;
      margin-bottom: 0;
      border: 1px solid var(--red);
      @media (--desktop){
        width: 30% !important;
      }
    }

    .summary.entry-summary {
      @media (--desktop){
        width: 66% !important;
      }

      .product_meta,
      .price {
        display: none;
      }
    }

    .product_title.entry-title {
      display: none;
    }

    .table {
      th,
      td {
        padding: .4em 1em;
        border-color: var(--pink);
        font: 400 16px/1.2 'Century Gothic', sans-serif;
        text-align: left;
        @media (--phone){
          font-size: 14px;
        }
      }
    }
  }
}
