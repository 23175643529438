.mainnav {
  .menu {
    &-item {
      text-transform: uppercase;
    }
  }
}

#mainnav {
  float: none;

  .menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &-item {
      &.current-menu-item,
      &.current-product_cat-ancestor,
      &.current-product-ancestor {
        > a {
          font-weight: 700;
          text-decoration: underline;
          color: var(--black);
        }
      }
    }
  }
}
