.contact {
  position: relative;
  overflow: visible !important;
  background: url(../images/home-about_bg_2.png) 104.4% 55%/20% auto no-repeat;
  @media (--phone){
    background-size: 30% auto;
    background-position-y: 55%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    padding-top: 34%;
    background: url(../images/home-about_bg_1.png) 50%/contain no-repeat;
    transform: translate(-25%, -40%);
    @media (--phone){
      width: 40%;
      padding-top: 44%;
    }
  }
}
